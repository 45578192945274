import React, { useState, useEffect } from 'react';
import { Translate, translate, logInfo, log } from 'react-jhipster'; //why log is added ?
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Alert, Button } from 'reactstrap';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { IRootState } from 'app/shared/reducers';
import { handleRegister, reset } from './register.reducer';

export interface IRegisterProps extends StateProps, DispatchProps {}

export const RegisterPage = (props: IRegisterProps) => {
  const [password, setPassword] = useState('');
  useEffect(
    () => () => {
      props.reset();
    },
    []
  );

  const handleValidSubmit = (event, values) => {
    props.handleRegister(
      values.ssn,
      values.firstname,
      values.lastname,
      values.address,
      values.mobilephone,
      values.username,
      values.email,
      values.firstPassword,
      props.currentLocale
    );
    event.preventDefault();
  };

  function updatePassword(event) {
    return setPassword(event.target.value);
  }

  function formatSSN(ssn) {
    // remove all non-dash and non-numerals
    let val = ssn.replace(/[^\d-]/g, '');

    // add the first dash if number from the second group appear
    val = val.replace(/^(\d{3})-?(\d{1,2})/, '$1-$2');

    // add the second dash if numbers from the third group appear
    val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');

    // remove misplaced dashes
    val = val
      .split('')
      .filter((vall, idx) => {
        return vall !== '-' || idx === 3 || idx === 6;
      })
      .join('');

    // enforce max length
    return val.substring(0, 11);
  }
  // document.getElementById('ssn').onkeyup = function (event) {
  //   this.value = formatSSN(this.value);
  // };
  function handleSSN() {
    const ssnValue = document.getElementById('ssn').value;
    document.getElementById('ssn').value = formatSSN(ssnValue);
  }
  function formatPhone(mobilephone) {
    // remove all non-dash and non-numerals
    let phone = mobilephone.replace(/[^\d-]/g, '');
    // add the first dash if number from the second group appear
    phone = phone.replace(/^(\d{3})-?(\d{3})/, '$1-$2');
    // add the second dash if numbers from the third group appear
    phone = phone.replace(/^(\d{3})-?(\d{3})-?(\d{1,4})/, '$1-$2-$3');
    // remove misplaced dashes
    phone = phone
      .split('')
      .filter((vall, idx) => {
        return vall !== '-' || idx === 3 || idx === 7;
      })
      .join('');
    // enforce max length
    return phone.substring(0, 12);
  }

  function handlePhone() {
    const phoneValue = document.getElementById('mobilephone').value;
    document.getElementById('mobilephone').value = formatPhone(phoneValue);
  }
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h1 id="register-title">
            <Translate contentKey="register.title">Registration</Translate>
          </h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          <AvForm id="register-form" onValidSubmit={handleValidSubmit}>
            {/* <input type="text" label="SSN" placeholder="000-00-0000" id="ssn" onChange={handleSSN} /> */}
            <AvField
              name="ssn"
              label="SSN"
              placeholder="000-00-0000"
              onKeyUp={handleSSN}
              validate={{
                required: { value: true, errorMessage: translate('global.messages.validate.ssn.required') },
                pattern: {
                  //Add autopopulate to limit numbers and add - s 
                  value: '/^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/',
                  errorMessage: translate('global.messages.validate.ssn.pattern'),
                },
                maxLength: { value: 11, errorMessage: translate('global.messages.validate.ssn.maxlength') },
              }}
            />

          <AvField
              name="firstname"
              label={translate('global.form.name.label')}
              placeholder={translate('global.form.name.placeholder')}
              validate={{
                required: { value: true, errorMessage: translate('global.messages.validate.name.required') },
              }}
            />


          <AvField
              name="lastname"
              label={translate('global.form.lastname.label')}
              placeholder={translate('global.form.lastname.placeholder')}
              validate={{
                required: { value: true, errorMessage: translate("global.messages.validate.lastname.required") },
              }}
            />

           {/* Enter Address information below No PO Boxes allowed */}
           <AvField 
              name="address" 
              label={translate('global.form.address.label')} 
              placeholder={translate('global.form.address.placeholder')}
              validate={{
                required: { value: true, errorMessage: translate('global.messages.validate.address.required') }
              }}
              
              
       />


        <AvField
              name="mobilephone"
              label={translate('global.form.mobilephone.label')}
              placeholder={translate('global.form.mobilephone.placeholder')}
              onKeyUp={handlePhone}
              validate={{
                required: { value: true, errorMessage: translate('global.messages.validate.mobilephone.required') },
                pattern: {
                  value: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
                  errorMessage: translate('global.messages.validate.mobilephone.pattern'),
                },
                maxLength: { value: 12, errorMessage: translate('global.messages.validate.mobilephone.maxlength') },
              }}
            />
            <AvField
              name="username"
              label={translate('global.form.username.label')}
              placeholder={translate('global.form.username.placeholder')}
              validate={{
                required: { value: true, errorMessage: translate('register.messages.validate.login.required') },
                pattern: {
                  value: '^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$',
                  errorMessage: translate('register.messages.validate.login.pattern'),
                },
                minLength: { value: 1, errorMessage: translate('register.messages.validate.login.minlength') },
                maxLength: { value: 50, errorMessage: translate('register.messages.validate.login.maxlength') },
              }}
            />
            <AvField
              name="email"
              label={translate('global.form.email.label')}
              placeholder={translate('global.form.email.placeholder')}
              type="email"
              validate={{
                required: { value: true, errorMessage: translate('global.messages.validate.email.required') },
                pattern: { 
                  value: '^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+\\.)+([a-zA-Z0-9]{2,4})+$',
                  errorMessage: translate('global.messages.validate.email.invalid'),
                },
                minLength: { value: 5, errorMessage: translate('global.messages.validate.email.minlength') },
                maxLength: { value: 254, errorMessage: translate('global.messages.validate.email.maxlength') },
              }}
            />
            <AvField
              name="firstPassword"
              label={translate('global.form.newpassword.label')}
              placeholder={translate('global.form.newpassword.placeholder')}
              type= "password"
              onChange={updatePassword}
              validate={{
               pattern: {value: '/[a-zA-Z0-9]+[!$&#*+=?^_`{|}~.-]+/g', errorMessage: translate('global.messages.validate.newpassword.pattern') },
               required: { value: true, errorMessage:translate('global.messages.validate.newpassword.required') },
               minLength: { value: 5, errorMessage: translate('global.messages.validate.newpassword.minlength')},
               maxLength: { value: 50, errorMessage: translate('global.messages.validate.newpassword.maxlength')},
               
              }}
            />
           
            <PasswordStrengthBar password={password} />
            <AvField
              name="secondPassword"
              label={translate('global.form.confirmpassword.label')}
              placeholder={translate('global.form.confirmpassword.placeholder')}
              type="password"
              validate={{
                required: { value: true, errorMessage: translate('global.messages.validate.confirmpassword.required') },
                minLength: { value: 5, errorMessage: translate('global.messages.validate.confirmpassword.minlength') },
                maxLength: { value: 50, errorMessage: translate('global.messages.validate.confirmpassword.maxlength') },
                match: { value: 'firstPassword', errorMessage: translate('global.messages.error.dontmatch') },
              }}
            />
            <Button id="register-submit" color="primary" type="submit">
              <Translate contentKey="register.form.button">Register</Translate>
            </Button>
          </AvForm>
          <p>&nbsp;</p>
          <Alert color="warning">
            <span>
              <Translate contentKey="global.messages.info.authenticated.prefix">If you want to </Translate>
            </span>
            <a className="alert-link">
              <Translate contentKey="global.messages.info.authenticated.link"> sign in</Translate>
            </a>
            <span>
              <Translate contentKey="global.messages.info.authenticated.suffix">
                , you can try the default accounts:
                <br />- Administrator (login=&quot;admin&quot; and password=&quot;admin&quot;)
                <br />- User (login=&quot;user&quot; and password=&quot;user&quot;).
              </Translate>
            </span>
          </Alert>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = ({ locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
});

const mapDispatchToProps = { handleRegister, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
