import React, {Component} from "react";
import Section from "./layouts/Section";
import { Translate, translate, logInfo, log } from 'react-jhipster'; 
class Contact extends Component<{},{}> {
    componentDidMount(){
        window.scrollTo(0,0);
    }
    render() {
        return (
            <Section breadcrumb={true} title={'Contact'}>
                <div className="contact-page-content-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-contact-info-box">
                                    <div className="icon">
                                        <i className="fa fa-map-marker" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Address:</h4>
                                        <span className="details"> St. Zip. Encinitas. 260-C North El Camino,  USA</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-contact-info-box">
                                    <div className="icon">
                                        <i className="fa fa-phone" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Phone & Fax</h4>
                                        <span className="details">(888) 123-4567</span>
                                        <span className="details">(123) 123-4567</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-contact-info-box">
                                    <div className="icon">
                                        <i className="fa fa-envelope" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Email Address</h4>
                                        <span className="details">support@example.com</span>
                                        <span className="details">info@example.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="contact-map">
                                    <div className="mapouter">
                                        <div className="gmap_canvas">
                                            <iframe title="google map" id="gmap_canvas" src="https://maps.google.com/maps?q=Sheikh%20Tower%2C%20%20Bogra%205800&t=&z=13&ie=UTF8&iwloc=&output=embed" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               <section className="contact-area">
 <div className="container">
    <div className="row">
        <div className="col-lg-12">
            <div className="contact-bottom-inner">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="form-content-area">
                          <h3 className="title text-center">Contact Us</h3>
                            <div className="contact-form-wrapper">
<form method="POST" id="contactusForm" className="contact-form">
<div className="row">
    <div className="col-lg-6">
        <div className="form-element ">
            <label> {translate('global.form.fullname.label')}</label>
            <input type="text" id="name"  name="name" placeholder={translate('global.form.fullname.placeholder')} className="input-field borderd" pattern="[A-Za-z ]{3,}" required/>
            <span role="alert" id="missingName" aria-hidden="true"> 
            {translate('global.form.fullname.message')}
            </span>
        </div>
        <div className="form-element ">
            <label> {translate('global.form.email.label')}</label>
            <input type="email" id="email" name="email" placeholder={translate('global.form.email.message')} className="input-field borderd" pattern="[A-Za-z0-9\.]{1,}@[A-Za-z]{1,}\.com$" required/>
            <span role="alert" id="missingEmail" aria-hidden="true">  
            {translate('global.form.email.placeholder')}
            </span>
        </div>
    </div>
    <div className="col-lg-6">
        <div className="form-element ">
            <label>{translate('global.form.subject.label')}</label>
            <input type="text" id="subject" name="subject" placeholder={translate('global.form.subject.placeholder')} className="input-field borderd" pattern="[A-Za-z ]{4,}" required />
            <span role="alert" id="missingSubject" aria-hidden="true"> 
            {translate('global.form.subject.message')}
            </span>
        </div>
        <div className="form-element ">
            <label>{translate('global.form.contactphone.label')}</label>
            <input type="tel" id="phone" name="phone" placeholder={translate('global.form.contactphone.message')} className="input-field borderd" pattern="(?=(?:^(?:\+?1\s*(?:[.-]\s*)?)?(?!(?:(?:.*\(.*)|(?:.*\).*)))(?:[2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))|(?:.*\((?:[2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\).*))(?:\+?1\s*(?:[.-]\s*)?)?(?:\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\)?)\s*(?:[.-]\s*)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d{1,15}))?$" required/>
            <span role="alert" id="missingPhone" aria-hidden="true"> 
            {translate('global.form.contactphone.placeholder')}
            </span>
        </div>
    </div>
    <div className="col-lg-12">
            <label>{translate('global.form.request.label')}</label>
         <textarea rows={10} cols={30} id="message" name="message" placeholder={translate('global.form.request.placeholder')} className="input-field borderd textarea" required/>
         <span role="alert" id="missingMessage" aria-hidden="true"> 
         {translate('global.form.request.message')}
        </span>
    </div>
</div>    
    <input type="submit" id="submit" className="btn btn-blue" value={translate('global.form.sendmessage')} />               
</form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</section>
            </Section>
        );
    }
}
export default Contact;
